.videosDiv {
  width: auto;
  margin: 8px;
  background-color: rgb(48, 135, 50);
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.videosDivDiv {
  width: 85%;
  display: block;
}
.videosTitle {
  color: white;
  padding: 10px;
  font-weight: bolder;
  font-size: 40px;
}

.titleTakkenGeneral {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin: 10px;
  border-radius: 10px;
  background-color: rgb(22, 104, 22);
  padding: 10px;
}

.takkenButtons {
  display: block;
}

.videosLink {
  color: white;
  padding: 10px;
  text-align: center;
  font-weight: 500;
}

.videosA {
  text-decoration: none;
}

@media (max-width: 768px) {
  .videosDiv {
    display: block;
  }
}
