.diaavondDiv {
    width: auto;
    margin: 5px;
    background-color: rgb(48, 135, 50);
    padding: 5px;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
  }
  .diaavondSection {
    width: 80%;
    background-color: rgb(22, 104, 22);
    border-radius: 10px;
    padding: 15px;
  }
  
  .diaavondTitle {
    color: white;
    padding: 10px;
    font-weight: 700;
    font-size: 40px;
  }
  
  .diaavondText{
    color: white;
    padding: 10px;
    font-size: 17px;
  }
  
  .diaavondImage {
    padding: 5px;
    width: 95%;
    height: auto;
  }
  
  @media (max-width: 768px) {
    .newsImg {
        width: 100%;
        height: auto;
        padding: 0px
      }

    .diaavondSection {
        width: 100%;
        padding: 0px;
    }

    .diaavondDiv {
      display: block;
    }
  }
  