.slide-container {
  width: 100%;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.each-slide {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 550px;
}

.image {
  width: 100%;
  height: auto;
  justify-content: center;
  align-items: center;
}

/* Span is not used 
.each-slide span {
  padding: 20px;
  font-size: 20px;
  background: #efefef;
  text-align: center;
}
*/

@media (max-width: 768px) {
  .image {
    width: 100%;
  }
  .each-slide {
    height: auto;
  }
}
