.albumDiv {
  width: auto;
  margin: 5px;
  background-color: rgb(48, 135, 50);
  padding: 5px;
  border-radius: 10px;
  text-align: center;
}

.albumDivNoClick {
  width: auto;
  margin: 5px;
  background-color: rgb(48, 135, 50);
  padding: 5px;
  border-radius: 10px;
  text-align: center;
  pointer-events: none;
}
.albumImage {
  width: 25%;
  height: auto;
  padding: 10px;
}

.albumImageIFrame {
  width: 95%;
  padding: 10px;
}

.albumTitle {
  color: white;
  background-color: rgb(22, 104, 22);
  font-size: 45px;
  font-weight: bold;
  padding: 10px;
  margin: 5px;
  border-radius: 10px;
  text-align: center;
}

@media (max-width: 768px) {
  .albumImage {
    width: 90%;
  }
}
