.verhuurDiv {
  width: auto;
  margin: 8px;
  background-color: rgb(48, 135, 50);
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}
.verhuurSection {
  width: 80%;
  background-color: rgb(22, 104, 22);
  border-radius: 10px;
  padding: 15px;
  margin: auto;
}

.verhuurTitle {
  color: white;
  padding: 10px;
  font-weight: 700;
  font-size: 40px;
}

.verhuurAnswer {
  color: white;
  padding: 10px;
  font-size: 17px;
}

.verhuurImage {
  width: 100%;
}

@media (max-width: 768px) {
  .verhuurDiv {
    display: block;
  }
}
