.textDiv {
  width: 80%;
  background-color: rgb(22, 104, 22);
  margin: 5px;
  border-radius: 20px;
}

.sideWidget {
  width: 30%;
  background-color: rgb(22, 104, 22);
  margin: 5px;
  border-radius: 20px;
}

.paragraphTakken {
  font-size: 20px;
  padding: 20px;
}

.titleTakken {
  font-size: 40px;
  color: white;
  padding-top: 20px;
  padding-left: 20px;
}

.mailadresTakken {
  font-size: 15;
  color: white;
  border-bottom-style: solid;
  border-width: 2px;
  margin-left: 23px;
  padding-bottom: 15px;
}

.videosDivDiv {
  width: 100%;
}

.titleTakkenGeneral {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  margin: 10px;
  border-radius: 10px;
  background-color: rgb(22, 104, 22);
}

.takkenButtons {
  margin: auto;
}

@media (max-width: 768px) {
  .textDiv {
    width: 95%;
    margin: 5px auto;
  }
}
