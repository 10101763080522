.newsBox {
  color: white;
  padding: 10px;
}

.titleNews {
  font-weight: bold;
  font-size: 40px;
  border-bottom-style: solid;
  border-width: 3px;
  margin-right: 10px;
  margin-left: 10px;
}

.newsBox p {
  margin-left: 10px;
  margin-top: 10px;
}

h2 {
  margin-left: 10px;
}

.newsImg {
  padding: 10px;
  width: 95%;
  height: auto;
}

@media (max-width: 768px) {
  .newsImg {
    width: 95%;
    height: auto;
  }
  .titleNews {
    font-size: 30px;
  }
}
