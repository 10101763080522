.inschrijven {
  width: auto;
  margin: 5px;
  background-color: rgb(48, 135, 50);
  padding: 5px;
  display: flex;
  justify-content: space-between;
  border-radius: 10px;
}

.uitlegDiv {
  width: 93%;
  background-color: rgb(22, 104, 22);
  border-radius: 10px;
  padding: 15px;
}

.enrollFormTitle {
  border-top-style: solid;
  padding: 15px;
  color: white;
}

h3 {
  color: white;
  margin-left: 10px;
  padding-bottom: 10px;
}

h1 {
  color: white;
  margin-left: 10px;
  padding-bottom: 10px;
}

.noBull {
  list-style-type: none;
 }

@media (max-width: 768px) {
  .inschrijven {
    display: block;
  }
}
