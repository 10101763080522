.box {
  color: white;
  padding: 10px;
  border-bottom-style: solid;
  border-width: 1px;
  border-radius: 20px;
  margin: 3px;
}

.event {
  font-weight: bold;
}
