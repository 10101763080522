.slide-containerSponsor {
  width: auto;
  margin: auto;
  align-items: center;
  justify-content: center;
}

.each-slideSponsor {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: fit-content;
}

.imageSponsor {
  height: 200px;
  width: auto;
  justify-content: center;
  align-items: center;
}

.slide-containerSponsor .images-wrap {
  height: 220px;
}

.slide-containerSponsor .images-wrap > div {
  margin: 15px;
  white-space: nowrap !important;
  width: fit-content !important;
  height: 200px;
}

@media (max-width: 768px) {
  .imageSponsor {
    width: 70%;
  }
  .each-slideSponsor {
    height: 120px;
    align-items: flex-start;
    justify-content: flex-start;
  }
  .slide-containerSponsor {
    width: auto;
    margin: auto;
    align-items: flex-start;
    justify-content: flex-start;
  }
}
