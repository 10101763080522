.infoLeiding {
  width: auto;
  margin: 8px;
  background-color: rgb(48, 135, 50);
  padding-bottom: 25px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  border-radius: 10px;
  display: flex;
}

.title {
  color: white;
  padding: 10px;
  font-weight: bolder;
  font-size: 40px;
}

.table {
  width: 100%;
}

td {
  border: 1px solid rgb(22, 104, 22);
  text-align: left;
  color: white;
  padding: 10px;
}

th {
  border: 1px solid rgb(22, 104, 22);
  text-align: left;
  color: white;
  padding: 18px;
  text-align: center;
  background-color: rgb(22, 104, 22);
}

tr:nth-child(even) {
  background-color: rgb(25, 117, 25);
}

.emailTable {
  font-size: 16px;
  text-decoration: none;
}

.titleTable {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  padding: 10px;
  border-radius: 10px;
  background-color: rgb(22, 104, 22);
}

@media (max-width: 768px) {
  .infoLeiding {
    display: block;
  }
  td {
    font-size: 12px;
  }
}
