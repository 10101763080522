.photosButtonsDiv {
  width: auto;
  margin: 5px;
  background-color: rgb(48, 135, 50);
  padding: 50px;
  display: flex;
  justify-content: space-around;
  border-radius: 10px;
}

.photosTextDiv {
  width: auto;
  margin: 8px;
  background-color: rgb(48, 135, 50);
  padding: 50px;
  display: block;
  justify-content: space-around;
  border-radius: 10px;
}

.photosTextDiv2 {
  width: auto;
  margin: 5px;
  background-color: rgb(48, 135, 50);
  padding: 30px;
  display: block;
  text-align: center;
  justify-content: space-around;
  border-radius: 10px;
}

.titlePhotos {
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 25px;
  padding: 20px;
  border-radius: 10px;
  /* background-color: rgb(22, 104, 22); */
}

.yearPhotos {
  color: white;
  text-align: center;
  font-size: 20px;
  padding: 10px;
}

.button {
  background-color: #4caf50; /* Green */
  border: none;
  color: white;
  padding: 10px 18px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  border-radius: 10px;
}
