section.info {
    width: 80%;
    margin: 0.5%;
    background-color: rgb(48, 135, 50);
    padding: 5px 9.5%;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
}

.news {
    width: 70%;
    background-color: rgb(22, 104, 22);
    margin: 5px;
    border-radius: 20px;
}

.calender,
.slideShow {
    border-radius: 20px;
    padding: 1px 0px;
    background-color: rgb(22, 104, 22);
}

.sideSpace > div,
.containerWidgets > div {
    width: 95%;
    padding: 0px;
    margin: 0px;
    margin-left: 10px;
}

div.calender {
    padding-top: 1px;
}

div.containerWidgets {
    margin: 0px;
    width: 100%;
    max-width: 500px;
}

.sideSpace > .calender ~ .slideShow + div.containerWidgets {
    max-width: initial;
}

div.slideShow {
    padding: 10px 0px;
}

.sideSpace {
    width: 35%;
    margin: 5px;
    border-radius: 20px;
}

.titleCalendar {
    font-size: 40px;
    color: white;
    border-bottom-style: solid;
    border-width: 3px;
    margin: 10px;
}

.newsImg {
    max-width: 98%;
    width: auto;
    height: auto;
}

#calendarImg {
    height: 0.5em;
    width: auto;
    float: right;
    margin-top: 0.5em;
    display: block;
}

@media (max-width: 930px) {
    section.info {
        width: calc(100% - 10px);
        display: flex;
        padding: 5px 0px;
        margin: 5px;
    }
}

@media (max-width: 768px) {
    section.info {
        display: block;
    }

    .sideSpace {
        width: inherit;
    }

    .sideSpace > div,
    .containerWidgets > div,
    .news {
        width: 95%;
        padding: 0px;
        margin: auto;
    }

    div.slideShow {
        padding-bottom: 10px;
    }

    .news {
        margin: 5px auto;
    }

    div.containerWidgets {
        width: 100%;
        max-width: 800px;
    }

    .home {
        width: 100%;
    }
}
