.widget {
  background-color: rgb(22, 104, 22);
  border-radius: 20px;
  margin: 5px;
}

.widgetText {
  color: white;
  padding: 10px;
  margin: 10px;
  margin-top: 25px;
}

.widgetLogo {
  width: 90%;
  padding: 10px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
}

@media (max-width: 768px) {
  .widgetSponsor {
    display: none;
  }
}
